<template>
    <div>
        <b-field :message="errors && errors['saved_search']"  :type="{ 'is-danger': hasError('saved_search') }" label="Saved search">
            <b-input v-model="daily_download.saved_search"/>
        </b-field>
        <b-field :message="errors && errors['export_format']"  :type="{ 'is-danger': hasError('export_format') }" label="Export format">
            <b-input v-model="daily_download.export_format"/>
        </b-field>
        <b-field :message="errors && errors['export_format']"  :type="{ 'is-danger': hasError('export_format') }" label="Status">
            <b-switch v-model="daily_download.status" :true-value="1" :false-value="0">Status</b-switch>
        </b-field>
        <b-field>
            <b-button type="is-primary" @click="updateCredentials">Save</b-button>
        </b-field>
    </div>
</template>

<script>
export default {
    name: "DailyDownload",
    props: ['daily_download'],
    data() {
        return {
            errors: [],
            password: ''
        }
    },
    methods: {

        hasError(key) {
            return this.errors.hasOwnProperty(key);
        },
        async updateCredentials() {
            const response = await axios.post('/api/v1/mls/settings/daily_download/' + this.daily_download.id, this.daily_download)
                .catch(error => {
                    if (typeof error.response.data === 'object') {
                        this.errors = error.response.data.errors;
                    } else {
                        this.errors = ['Something went wrong. Please try again.'];
                    }
                });

            if (response && response.data) {

                this.$buefy.toast.open({
                    message: response.data.message,
                    type: 'is-success',
                    position: 'is-bottom',
                })

                this.password = '';
            }
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <form action="">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Select date</p>
                <button
                    type="button"
                    class="delete"
                    @click="$emit('close')"/>
            </header>
            <section class="modal-card-body">
                <b-datepicker
                    placeholder="Click to select..."
                    @input="handleInput"
                    range>
                </b-datepicker>
            </section>
            <footer class="modal-card-foot">
                <b-button
                    label="Close"
                    @click="$emit('close')"/>
                <b-button
                    @click="handleClick"
                    label="Run MLS Download"
                    type="is-primary"/>
            </footer>
        </div>
    </form>
</template>

<script>
export default {
    name: "MlsDownloadModal",
    data() {
        return {
            selected_date: []
        }
    },
    methods: {
        handleClick() {
            this.$emit('click', this.selected_date)
        },
        handleInput(data) {
            this.selected_date = data;
        }
    }
}
</script>

<style scoped>

</style>

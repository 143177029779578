<template>
    <section class="section">
        <h1 class="title">{{ mls.name }} Settings</h1>

        <b-tabs v-model="activeTab">
            <b-tab-item label="Account">
                <Account :mls="mls" />
            </b-tab-item>

            <b-tab-item label="Daily Downloads">
                <DailyDownload :daily_download="daily_download"></DailyDownload>
            </b-tab-item>

            <b-tab-item label="Expired Download">
                <ExpiredDownloadConfig :expired_download="expired_download" :mls="mls" :triggers="triggers"/>
            </b-tab-item>
        </b-tabs>
    </section>
</template>

<script>
import ExpiredDownloadConfig from "./configs/ExpiredDownloadConfig";
import Account from "./configs/Account";
import DailyDownload from "./configs/DailyDownload";

export default {
    name: "Config",
    components: {DailyDownload, Account, ExpiredDownloadConfig},
    props: ['mls', 'triggers', 'expired_download', 'daily_download'],
    data() {
        return {
            activeTab: 0,
        }
    }
}
</script>

<style scoped>

</style>

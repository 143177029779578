<template>
    <div>
        <b-field grouped>
            <b-field :message="errors['expired_download.expired_saved_search']"  :type="{ 'is-danger': hasError('expired_download.expired_saved_search') }" expanded label="Expired saved search">
                <b-input v-model="expired_download.expired_saved_search"></b-input>
            </b-field>
            <b-field :message="errors['expired_download.expired_export_format']"  :type="{ 'is-danger': hasError('expired_download.expired_export_format') }"  expanded label="Expired export format">
                <b-input v-model="expired_download.expired_export_format"></b-input>
            </b-field>
        </b-field>
        <b-field grouped>
            <b-field :message="errors['expired_download.lookup_one_saved_search']"  :type="{ 'is-danger': hasError('expired_download.lookup_one_saved_search') }" expanded label="Lookup 1 saved search">
                <b-input v-model="expired_download.lookup_one_saved_search"></b-input>
            </b-field>
            <b-field :message="errors['expired_download.lookup_one_export_format']"  :type="{ 'is-danger': hasError('expired_download.lookup_one_export_format') }" expanded label="Lookup 1 export format">
                <b-input v-model="expired_download.lookup_one_export_format"></b-input>
            </b-field>
        </b-field>
        <b-field grouped>
            <b-field :message="errors['expired_download.lookup_two_saved_search']"  :type="{ 'is-danger': hasError('expired_download.lookup_two_saved_search') }" expanded label="Lookup 2 saved search">
                <b-input v-model="expired_download.lookup_two_saved_search"></b-input>
            </b-field>
            <b-field :message="errors['expired_download.lookup_two_export_format']"  :type="{ 'is-danger': hasError('expired_download.lookup_two_export_format') }" expanded label="Lookup 2 export format">
                <b-input v-model="expired_download.lookup_two_export_format"></b-input>
            </b-field>
        </b-field>
        <b-field grouped>
            <b-field expanded label="Status">
                <b-switch v-model="expired_download.status"/>
            </b-field>
        </b-field>

        <b-field label="Triggers"/>
        <div class="columns is-multiline is-mobile">
            <div class="column is-one-quarter" v-for="(day,key) in days" :key="key">
                <b-collapse :open="false" class="card show" animation="slide" :aria-id="day.key">
                    <template #trigger="props">
                        <div
                            class="card-header"
                            role="button"
                            :aria-controls="key">
                            <p class="card-header-title">
                                {{ key }}
                            </p>
                            <a class="card-header-icon">
                                <b-icon
                                    :icon="props.open ? 'menu-down' : 'menu-up'">
                                </b-icon>
                            </a>
                        </div>
                    </template>

                    <div class="card-content">
                        <div class="content">
                            <div v-for="(time, timeKey) in day" class="columns is-vcentered is-mobile">
                                <div class="column">
                                    <b-timepicker hour-format="24" size="is-small"
                                                  v-model="days[key][timeKey].trigger_at"
                                                  inline></b-timepicker>
                                </div>
                                <div class="column">
                                    <b-switch v-model="days[key][timeKey].status"/>

                                </div>
                                <div class="column">
                                    <b-button type="is-danger" @click="removeTimeSlot(key,timeKey)"
                                              class="delete"/>
                                </div>
                            </div>
                            <b-button type="is-success" @click="addTimeSlot(key)">Add new</b-button>
                        </div>

                    </div>
                </b-collapse>
            </div>
        </div>
        <b-button class="is-success" @click="saveExpiredDownload">Save</b-button>
    </div>
</template>

<script>
export default {
    name: "ExpiredDownloadConfig",
    props: ['mls', 'triggers', 'expired_download'],
    data() {
        return {
            days: [],
            errors: [],
            allDays: {
                "MONDAY": [],
                "TUESDAY": [],
                "WEDNESDAY": [],
                "THURSDAY": [],
                "FRIDAY": [],
                "SATURDAY": [],
                "SUNDAY": [],
            }
        }
    },
    methods: {
        hasError(key) {
            return this.errors.hasOwnProperty(key);
        },
        async saveExpiredDownload() {
            let slots = [];
            const days = _.cloneDeep(this.days);
            Object.keys(days).forEach(key => {
                let items = days[key]

                if (items) {
                    slots = [...slots, ...items]
                }
            })

            slots = slots.map(item => {
                item.trigger_at = this.$date(item.trigger_at).format('HH:mm');
                return item;
            })

            const response = await axios.post('/api/v1/mls/settings/expired-downloads/' + this.mls.slug, {
                slots,
                mls_id: this.mls.id,
                expired_download: this.expired_download
            })
                .catch(error => {
                    if (typeof error.response.data === 'object') {
                        this.errors = error.response.data.errors;
                    } else {
                        this.errors = ['Something went wrong. Please try again.'];
                    }
                });

            if (response && response.data) {

                this.$buefy.toast.open({
                    message: response.data.message,
                    type: 'is-success',
                    position: 'is-bottom',
                })
            }
        },
        addTimeSlot(day) {
            this.days[day].push({
                'status': true,
                'trigger_at': new Date(),
                'trigger_day': day
            })
        },
        removeTimeSlot(dayIndex, timeIndex) {
            this.days[dayIndex] = this.days[dayIndex].filter((item, key) => {
                return timeIndex !== key
            })
        }
    },
    created() {
        let data = [];
        const groupBy = (items, key) => items.reduce(
            (result, item) => ({
                ...result,
                [item[key]]: [
                    ...(result[item[key]] || []),
                    item,
                ],
            }),
            {},
        );
        data = this.triggers.map(item => {
            item.trigger_at = this.$date(item.trigger_at, "HH:mm").toDate()
            return item;
        })
        data = groupBy(data, 'trigger_day');
        this.days = {...this.allDays, ...data}
    }
}
</script>

<style scoped>

</style>

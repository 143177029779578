<template>
    <div>
        <b-field :message="errors['username']"  :type="{ 'is-danger': hasError('username') }" label="Username">
            <b-input v-model="mls.username"/>
        </b-field>
        <b-field :message="errors['password']"  :type="{ 'is-danger': hasError('password') }"  label="Password">
            <b-input v-model="password" type="password"/>
        </b-field>
        <b-field>
            <b-button type="is-primary" @click="updateCredentials">Save</b-button>
        </b-field>
    </div>
</template>

<script>
export default {
    name: "Account",
    props: ['mls'],
    data() {
        return {
            errors: [],
            password: ''
        }
    },
    methods: {

        hasError(key) {
            return this.errors.hasOwnProperty(key);
        },
        async updateCredentials() {
            const response = await axios.post('/api/v1/mls/settings/account/' + this.mls.slug, {username: this.mls.username, password: this.password})
                .catch(error => {
                    if (typeof error.response.data === 'object') {
                        this.errors = error.response.data.errors;
                    } else {
                        this.errors = ['Something went wrong. Please try again.'];
                    }
                });

            if (response && response.data) {

                this.$buefy.toast.open({
                    message: response.data.message,
                    type: 'is-success',
                    position: 'is-bottom',
                })

                this.password = '';
            }
        }
    }
}
</script>

<style scoped>

</style>

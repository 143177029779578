<template>
    <div>
        <section>
            <b-field grouped group-multiline>
                <b-button
                    label="Run MLS"
                    type="is-primary"
                    class="field"
                    @click="isRunDownloadModalActive = true" />
            </b-field>

            <b-table
                :data="mls_scans"
                :checked-rows.sync="checkedRows"
                checkable
                @check="handleCheck"
                :checkbox-position="checkboxPosition">

                <b-table-column field="name" label="MLS" v-slot="props">
                    {{ props.row.name }}
                </b-table-column>
                <b-table-column field="name" label="User" v-slot="props">
                    {{ props.row.username }}
                </b-table-column>
                <b-table-column field="name" label="File" v-slot="props">
                    {{ props.row.last_scan ? props.row.last_scan.file : '-' }}
                </b-table-column>
                <b-table-column field="name" label="Date Range" v-slot="props">
                    {{ props.row.last_scan ? props.row.last_scan.download_date_range : '-' }}
                </b-table-column>
                <b-table-column field="name" label="Status" v-slot="props">
                    {{ props.row.last_scan ? props.row.last_scan.status : '-' }}
                </b-table-column>
                <b-table-column field="name" label="Scanning" v-slot="props">
                    {{ props.row.last_scan ? props.row.last_scan.is_scanning : '-' }}
                </b-table-column>
                <b-table-column field="name" label="Triggered" v-slot="props">
                    {{ props.row.last_scan ? props.row.last_scan.created_at : '-' }}
                </b-table-column>
                <b-table-column field="action" label="Action" v-slot="props">
                    <b-button tag="a"
                              :href="`/mls/config/${props.row.slug}`"
                              icon-right="cog-outline" />
                    <b-button
                              v-if="props.row.last_scan && props.row.last_scan.status === 'failed'"
                              type="is-danger"
                              @click="executeScan(props.row.last_scan.download_date_range,[props.row.id])"
                              icon-right="refresh" />
                </b-table-column>

                <template #bottom-left>
                    <b>Total checked</b>: {{ checkedRows.length }}
                </template>
            </b-table>

        </section>
        <b-modal
            v-model="isRunDownloadModalActive"
            has-modal-card
            trap-focus
            :width="640"
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-label="Select Date Range"
            aria-modal>
            <template #default="props">
                <mls-download-modal @click="runMlsScan" @close="props.close"></mls-download-modal>
            </template>
        </b-modal>

        <div class="modal fade" id="modal-select-date" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            Select Date range
                        </h4>

                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>

                    <div class="modal-body">

                        <div class="alert alert-danger" v-if="errors.length > 0">
                            <ul>
                                <li v-for="error in errors">
                                    {{ error }}
                                </li>
                            </ul>
                        </div>

                        <form role="form">
                            <div class="form-group row">
                                <label class="col-md-3 col-form-label">Date</label>

                                <div class="col-md-9">

                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>

                        <button type="button" class="btn btn-primary" @click="runMlsScan">
                            Run MLS Download
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import MlsDownloadModal from "./MlsDownloadModal";

export default {
    name: "MlsScans",
    components: {
        MlsDownloadModal
    },
    data() {
        return {
            checkboxPosition: 'left',
            checkedRows: [],
            columns: [
                {
                    field: 'name',
                    label: 'MLS',
                },
                {
                    field: 'username',
                    label: 'User',
                },
                {
                    field: 'last_scan.file',
                    label: 'File',
                },
                {
                    field: 'last_scan.download_date_range',
                    label: 'Date',
                    centered: true
                },
                {
                    field: 'last_scan.status',
                    label: 'Status',
                },
                {
                    field: 'last_scan.is_scanning',
                    label: 'Scanning?',
                },
                {
                    field: 'last_scan.created_at',
                    label: 'Triggered',
                }
            ],
            isRunDownloadModalActive: false,
            mls_scans: [],
            selected_mls: [],
            interval: null,
            date_range: [],
            message: '',
            errors: [],
        }
    },
    created() {
        this.getMlsScans()
    },
    computed: {

        maxDate() {
            let date = new Date();
            date.setDate(date.getDate());
            return date;
        }
    },
    methods: {

        async getMlsScans() {
            const response = await axios.get('/get-mls')
            this.mls_scans = response.data.mls.data;

            if (response.data.pending > 0 && this.interval === null) {
                this.interval = setInterval(() => this.getMlsScans(), 5000);
            } else if (response.data.pending <= 0) {
                clearInterval(this.interval);
                this.interval = null;
            }
        },
        async sendFile(mls_name, user_name) {

            axios.post('/api/v1/scan-mls-send-permission/' + user_name, {mls_name})
                .then(response => {
                    $('#modal-select-date').modal('hide');
                    this.message = response.data.message
                    this.getMlsScans();
                })
                .catch(error => {
                    if (typeof error.response.data === 'object') {
                        this.errors = _.flatten(_.toArray(error.response.data.errors));
                    } else {
                        this.errors = ['Something went wrong. Please try again.'];
                    }
                });
        },
        showDateModal() {
            $('#modal-select-date').modal('show');

        },
        handleCheck(rows) {
            this.selected_mls = rows.map(function (row, key) {
                return row.id;
            })
            console.log(this.selected_mls)
        },
        checkAllMls($event) {

            if ($event.target.checked) {

                this.selected_mls = this.mls_scans.map(function (item, key) {
                    return item.id;
                })
            } else this.selected_mls = [];
        },
        runMlsScan(selected_dates) {
            let date_range = '';
            if (selected_dates[0] && selected_dates[1]) {
                date_range = selected_dates[0].toLocaleDateString('en-US') + '-' + selected_dates[1].toLocaleDateString('en-US');
            }
            this.executeScan(date_range, this.selected_mls)
        },
        async executeScan(date_range, mls) {

            const response = await axios.post('/run-mls', {date_range, mls})
                .catch(error => {
                    if (typeof error.response.data === 'object') {
                        this.errors = _.flatten(_.toArray(error.response.data.errors));
                        this.$buefy.toast.open({
                            message: this.errors[0],
                            type: 'is-danger',
                            position: 'is-bottom',
                        })
                    } else {
                        this.errors = ['Something went wrong. Please try again.'];
                    }
                });

            if (response && response.data.hasOwnProperty('status') && response.data.status) {

                this.getMlsScans();
                this.selected_mls = [];

                this.$buefy.toast.open({
                    message: response.data.message,
                    type: 'is-success',
                    position: 'is-bottom',
                })
            }

            this.isRunDownloadModalActive = false
            this.date_range = []
        }
    }
}
</script>

<style>
.modal .animation-content .modal-card {
    overflow: visible !important;
}

.modal-card-body {
    overflow: visible !important;
}
section {
    padding: 1.5rem;
}
</style>
